import React, { useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { isEmpty } from '../../library/Core/SmartContext';

interface WidgetData {
    data: {
        wgdata: any;
        wginfodata: {
            data: any
        }
    };
    internal: any;
}

interface AppealVideoProps {
    widgetData: WidgetData;
}

const AppealVideo: React.FC<AppealVideoProps> = ({ widgetData }) => {
    let data = widgetData?.data?.wginfodata?.data?.widgetData;
    const [showVideo, setShowVideo] = useState(false);
    const [ratioClass, setRatioClass] = useState('');

    const handleThumbnailClick = () => {
        setShowVideo(true); // Show video when thumbnail is clicked
        setRatioClass('bg-black');
    };

    const getEmbedUrl = (url: string | undefined) => {
        if (!url) return '';
        if (isEmpty(data?.thumbnail)) return url;
        return url.includes('?') ? `${url}&autoplay=1` : `${url}?autoplay=1`;
    };

    return (
        <>
            <h4 className="font-30 font-600 text-center mb-4">{data?.heading}</h4>
            <div className="bg-white border-radius-6 p-3 max-700 mx-auto">
                <div
                    className="videoWrap position-relative"
                    style={{ backgroundImage: `url(${data?.thumbnail})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}
                >
                    <div className={`ratio ratio-${data?.aspectRatio ?? '21x9'} ${ratioClass}`}>
                        {!showVideo ? (
                            <div>
                                <i className='icon-invert-play text-danger icon-video-play' onClick={handleThumbnailClick}></i>
                            </div>
                        ) : (
                            <iframe
                                src={getEmbedUrl(data?.embedUrl)}
                                title=""
                                style={{ border: 'none' }}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen={true}
                            ></iframe>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AppealVideo;