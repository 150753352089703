// src/library/Service/axiosClient.js

import axios from 'axios';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_WIDGET_API_SERVER, // Set your base URL here
    headers: {
        'Content-Type': 'application/json',
        // You can add more default headers here
    },
});

// Optional: Add interceptors for request and response handling
axiosClient.interceptors.request.use(
    config => {
        // Do something before request is sent, like adding a token
        return config;
    },
    error => {
        // Do something with request error
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    response => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    },
    error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        return Promise.reject(error);
    }
);

export default axiosClient;
