import { ArcElement, CategoryScale, Chart, Legend, PieController, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
// import { convertToPercentage } from '../Core/SmartContext';

Chart.register(ArcElement, CategoryScale, PieController, Tooltip, Legend);

const CustomPieChartComponent = ({ data }) => {
    const transformedData = transformData(data);

    const pieData = {
        labels: transformedData.map((item) => item.label),
        datasets: [
            {
                data: transformedData.map((item) => item.value),
                backgroundColor: getColorArray(Object.keys(transformedData).length),
                actualValue: transformedData.map((item) => item.actualValue),
            },
        ],
    };

    function transformData(data) {
        const dataArray = [...data];
        const THRESHOLD_VALUE = 1;
        dataArray.sort((a, b) => b.value - a.value);

        let topData = dataArray.filter((item) => item.value >= THRESHOLD_VALUE);
        const otherValue = dataArray
            .filter((item) => item.value < THRESHOLD_VALUE)
            .reduce((acc, item) => acc + parseInt(item.value, 10), 0);

        if (otherValue > 0) topData.push({ label: 'Other', value: otherValue });

        return topData;
    }

    function getColorArray(numDataPoints) {
        // Base colors - You can adjust or expand this array as needed.
        const baseColors = ['#FF6384', '#36A2EB', '#FFCE56', '#A569BD', '#3498DB', '#E74C3C', '#2ECC71', '#F1C40F', '#E67E22', '#1ABC9C'];

        // If the number of data points is less than or equal to the base colors length, slice the base colors.
        if (numDataPoints <= baseColors.length) {
            return baseColors.slice(0, numDataPoints);
        }

        // Else, get all the base colors and then generate the additional colors needed.
        const colors = [...baseColors];
        const extraColorsNeeded = numDataPoints - baseColors.length;

        for (let i = 0; i < extraColorsNeeded; i++) {
            // Generate a random color. You can improve the generation logic to avoid very light or very dark colors.
            const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
            colors.push(randomColor);
        }

        return colors;
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#333',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                    boxWidth: 10,
                    padding: 10,
                },
            },
            tooltip: {
                // <-- Add this configuration
                callbacks: {
                    label: function (context) {
                        // const originalData = data[context.dataIndex];
                        // return `${context.dataset.actualValue[context.dataIndex]}`;
                        return "";
                    },
                },
            },
            datalabels: {
                align: 'start',
                anchor: 'end',
                backgroundColor(context) {
                    return context.dataset.backgroundColor[context.dataIndex];
                },
                borderRadius: 4,
                offset: 12,
                clamp: true,
                color: '#fff',
                font: {
                    size: 14, // Adjust as per your liking
                    weight: 'bold',
                },
                formatter: (value) => `${value}%`,
                padding: 3,
            },
        },
    };

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '2px',
                overflow: 'hidden',
                backgroundColor: '#fff',
            }}>
            <Pie data={pieData} options={options} plugins={[ChartDataLabels]} />
        </div>
    );
};

export default CustomPieChartComponent;
