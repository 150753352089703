// Ref: https://github.com/ajaykumar97/react-native-simple-logger/blob/master/src/Logger.js

const log = (message) => {
    if (message instanceof Object) {
        console.log(
            '%c' + JSON.stringify(message, null, 4).substring(0, 20000).replace(/'/g, ''),
            'color: yellow; font-size: 12px; padding: 2px'
        );
        return;
    }
    console.log('%c' + message, 'color: green; font-size: 12px; padding: 2px');
};

const error = (message) => {
    console.error(message);
};

const logger = { log, error };

export default logger;
