import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Routes from './routes/routes';

function App() {
    useEffect(() => {
        AOS.init();
    }, []);

    const router = createBrowserRouter(Routes);

    return (
        <RouterProvider router={router} />
    );
}

export default App;
