import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { isEmpty } from '../../library/Core/SmartContext';

interface MetricData {
    value: number | string;
    rowUUID: string;
    metricType: string;
    metricEntity: string;
}

interface WidgetData {
    data: {
        wgdata: any;
        wginfodata: {
            data: {
                metricData: MetricData[];
                widgetData: {
                    other: number;
                    heading: string;
                    chartType: string;
                };
            }
        }
    };
    internal: any;
}

interface TopCareerProps {
    widgetData: WidgetData;
}

const TopCareerHorizontal: React.FC<TopCareerProps> = ({ widgetData }) => {
    let metricData = widgetData?.data?.wginfodata?.data?.metricData;
    let data = widgetData?.data?.wginfodata?.data?.widgetData;
    let metricEntityDomain = widgetData?.internal?.[widgetData?.data?.wgdata?.code];

    return (
        <div className="Vertical-graph-block mx-auto">
            <h2>{data?.heading}</h2>
            <div className="bigHorizontalProgress">
                {metricData?.filter((data: any) => (data.value > 0 && !isEmpty(data?.metricEntity))).map((data: any, index: any) => (
                    <div key={index} className="HorizontalProgress">
                        <div className="graphContainer">
                            <div className="progress lite-orange" data-bs-toggle="tooltip" title={`${data.value}%`}>
                                <div className="progress-bar dark-brown" role="progressbar" style={{ width: `${data.value}%` }} aria-valuenow={data.value} aria-valuemin={0} aria-valuemax={100}></div>
                                <span className="progresstext">{data.value}%</span>
                            </div>
                        </div>
                        <label className="caption">
                            <span className="font-14 font-500">{metricEntityDomain[data.metricEntity]?.value}</span>
                        </label>
                    </div>
                ))}

                {data.other > 0 && (
                    <div key="other" className="HorizontalProgress">
                        <div className="graphContainer">
                            <div className="progress lite-orange" data-bs-toggle="tooltip" title={`${data.other}%`}>
                                <div className="progress-bar dark-brown" role="progressbar" style={{ width: `${data.other}%` }} aria-valuenow={data.other} aria-valuemin={0} aria-valuemax={100}></div>
                                <span className="progresstext">{data.other}%</span>
                            </div>
                        </div>
                        <label className="caption">
                            <span className="font-14 font-500">Other</span>
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TopCareerHorizontal;