import React, { useEffect, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { isEmpty } from '../../library/Core/SmartContext';

interface MetricData {
    value: number | string;
    rowUUID: string;
    metricType: string;
    metricEntity2: string;
}

interface WidgetData {
    data: {
        wgdata: any;
        wginfodata: {
            data: {
                metricData: MetricData[];
                widgetData: any;
                popupData: any;
            }
        }
    };
    internal: any;
}

interface TopCompanyProps {
    widgetData: WidgetData;
}

const TopCompaniesHorizontal: React.FC<TopCompanyProps> = ({ widgetData }) => {
    let metricData = widgetData?.data?.wginfodata?.data?.metricData;
    let data = widgetData?.data?.wginfodata?.data?.widgetData;
    let metricEntityDomain = widgetData?.internal?.[widgetData?.data?.wgdata?.code];
    const popupData = widgetData?.data?.wginfodata?.data?.popupData;
    const [activeKey, setActiveKey] = useState("");

    const handleOnClick = (key: any) => {
        setActiveKey(key);
    }

    useEffect(() => {
        setActiveKey(metricData[0]['metricEntity2']);
    }, [metricData]);

    const responsiveOptions = {
        0: {
            items: 1,
        },
        575: {
            items: 2,
        },
        1000: {
            items: data?.gridLayout == '4_in_row' ? 4 : 5
        },
    };

    return (
        <>
            <h4 className="font-30 font-600 text-center mb-2">{data?.largeHeading}</h4>
            <p className="text-center pb-3">{data?.mediumHeading}</p>
            <div className="top-college-wrap">
                <div className="x-scroll">
                    <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                        {metricData?.filter((data: any) => data?.metricEntity2 > 0).map((data: any) => (
                            <li className="nav-item" role="presentation" key={data.metricEntity2}>
                                <button
                                    className={`nav-link ${data.metricEntity2 === activeKey ? 'active' : ''}`}
                                    id={`pills-${data.metricEntity2}-tab`}
                                    data-bs-toggle="pill"
                                    data-bs-target={`#pills-${data.metricEntity2}`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`pills-${data.metricEntity2}`}
                                    aria-selected={data.metricEntity2 === activeKey}
                                    onClick={() => handleOnClick(data.metricEntity2)}
                                >
                                    {metricEntityDomain[data.metricEntity2]?.value} ({data?.cnt})
                                </button>
                            </li>
                        ))}
                        {data?.other > 0 && (
                            <li className="nav-item" role="presentation" key="others">
                                <button
                                    className={`nav-link ${'others' === activeKey ? 'active' : ''}`}
                                    id="pills-others-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-others"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-others"
                                    aria-selected={'others' === activeKey}
                                    onClick={() => handleOnClick('others')}
                                >Others ({data?.other})</button>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="tab-content pt-2" id="pills-tabContent">
                    {Object.keys(popupData).map((key) => (
                        <div
                            key={key}
                            className={`tab-pane fade ${key === activeKey ? 'show active' : ''}`}
                            id={`pills-${key}`}
                            role="tabpanel"
                            aria-labelledby={`pills-${key}-tab`}
                        >
                            <OwlCarousel className="owl-theme top-colleges-slider" margin={10} nav autoWidth={false} dots={false} responsive={responsiveOptions}>
                                {popupData[key].map((listData: any) => (
                                    <div className="item" key={listData.id}>
                                        <div className="logo-block mx-auto">
                                            <div className="logo-img">
                                                {data?.logoFlag == 'show' && (
                                                    <img
                                                        src={!isEmpty(listData?.logo) ? listData?.logo : `${process.env.REACT_APP_IMAGE_BASEPATH}/building.svg`}
                                                        alt={listData?.name}
                                                        title={listData?.name}
                                                        data-bs-toggle="tooltip"
                                                        className="img-fluid"
                                                    />
                                                )}
                                            </div>
                                            {data?.logoFlag != 'show' && (
                                                <div className="college-name">{listData?.name}</div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    ))}
                    {!isEmpty(data?.otherList) && (
                        <div
                            className={`tab-pane fade ${'others' === activeKey ? 'show active' : ''}`}
                            id="pills-others"
                            role="tabpanel"
                            aria-labelledby="pills-others-tab"
                        >
                            <OwlCarousel className="owl-theme top-colleges-slider" margin={10} nav autoWidth={false} dots={false} responsive={responsiveOptions}>
                                {data?.otherList.map((listData: any) => (
                                    <div className="item" key={listData.id}>
                                        <div className="logo-block">
                                            <div className="logo-img">
                                                {data?.logoFlag == 'show' && (
                                                    <img
                                                        src={!isEmpty(listData?.logo) ? listData?.logo : `${process.env.REACT_APP_IMAGE_BASEPATH}/building.svg`}
                                                        alt={listData?.name}
                                                        title={listData?.name}
                                                        data-bs-toggle="tooltip"
                                                        className="img-fluid"
                                                    />
                                                )}
                                            </div>
                                            {data?.logoFlag != 'show' && (
                                                <div className="college-name">{listData?.name}</div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default TopCompaniesHorizontal;
