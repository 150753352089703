import * as moment from 'moment';
import logger from './logger';
import { evaluateExpressionWithValue, isEmpty } from './SmartContext';

const checkForRequiredField = (controlConfig, data, state, dataKey, label, errorMessages) => {
    const filedName = !isEmpty(controlConfig?.props?.errorLabel)
        ? controlConfig?.props?.errorLabel
        : !isEmpty(label)
        ? `"${label}"`
        : 'this value';
    return controlConfig?.required && (data === undefined || data === null || data.length === 0) && errorMessages.length === 0
        ? errorMessages.push(`Please enter ${filedName}`)
        : errorMessages;
};

const checkForMinAndMaxLength = (controlConfig, data, state, dataKey, label, errorMessages) => {
    const violatingMinLength = controlConfig?.props?.minLength && data.length < controlConfig?.props?.minLength;
    const violatingMaxLength = controlConfig?.props?.maxLength && data.length > controlConfig?.props?.maxLength;
    const filedName = label ? `"${label}"` : 'this value';

    if (violatingMinLength & !violatingMaxLength & (errorMessages.length === 0))
        errorMessages.push(`Please enter minimum ${controlConfig?.props?.minLength} characters for ${filedName}`);

    if (!violatingMinLength & violatingMaxLength & (errorMessages.length === 0))
        errorMessages.push(`Please enter less than ${controlConfig?.props?.maxLength} characters for ${filedName}`);

    if (violatingMinLength & violatingMaxLength & (errorMessages.length === 0))
        errorMessages.push(
            `Please enter between ${controlConfig?.props?.minLength} - ${controlConfig?.props?.maxLength} characters for ${filedName}`
        );

    return errorMessages;
};

const checkForMinAndMaxValue = (controlConfig, data, state, dataKey, label, errorMessages) => {
    if (controlConfig.props.type === 'date' && new Date(data) - new Date(controlConfig.props.min) < 0) {
        errorMessages.push(`Please enter date later than ${controlConfig?.props?.min} for "${label}"`);
    }

    if (controlConfig.props.type === 'date' && new Date(data) - new Date(controlConfig.props.max) > 0) {
        errorMessages.push(`Please enter date no later than ${controlConfig?.props?.min} for "${label}"`);
    }

    if (controlConfig.props.type === 'date' && controlConfig.props.max === 'TODAY' && new Date(data) - new Date() > 0) {
        errorMessages.push(`Please enter date no later than ${new Date().toLocaleDateString()} for "${label}"`);
    }

    if (controlConfig.props.type === 'date' && controlConfig.props.min === 'TODAY' && new Date(data) - new Date() < 0) {
        errorMessages.push(`Please enter date later than ${new Date().toLocaleDateString()} for "${label}"`);
    }
};

const checkForRegExPattern = (controlConfig, data, state, dataKey, label, errorMessages) => {
    if (controlConfig.props.pattern !== undefined) {
        const regex = new RegExp(controlConfig.props.pattern);
        const filedName = !isEmpty(label) ? `"${label}"` : 'this field';
        if (!regex.test(data)) errorMessages.push(`Please enter valid value for ${filedName}`);
    }
};

const checkCustomValidations = (controlConfig, data, row, state, dataKey, label, errorMessages) => {
    if (controlConfig?.customValidations === undefined) return;
    logger.log(`Evaluating the expression for ${dataKey} - Expression is ${controlConfig?.customValidations}`);
    const customValidations = JSON.parse(controlConfig?.customValidations);
    customValidations.map((validation) => {
        if (!evaluateExpressionWithValue(validation.expression, data, state.data, row)) errorMessages.push(validation.message);
    });
    logger.log(`Errors added for ${dataKey} are ${errorMessages.join(';')}`);
    return errorMessages;
};

const checkForRequiredFieldExpression = (controlConfig, data, row, state, dataKey, label, errorMessages) => {
    if (controlConfig?.requiredExpression === undefined) return;
    logger.log(`Evaluating the required expression for ${dataKey} - Expression is ${controlConfig?.requiredExpression}`);
    if (!evaluateExpressionWithValue(controlConfig?.requiredExpression, data, state.data, row))
        errorMessages.push(`Please enter "${label}"`);
    return errorMessages;
};

const checkForValidDataForType = (controlConfig, data, state, dataKey, label, errorMessages) => {
    if (controlConfig.props.type === 'date' && !moment(data).isValid()) {
        errorMessages.push(`Please enter valid date for "${label}"`);
    }
    return errorMessages;
};

export const validateFormField = (controlConfig, data, state, label, dataKey, row = {}) => {
    const errorMessages = [];
    const localData = data === undefined || data === null ? '' : data;
    checkForValidDataForType(controlConfig, localData, state, dataKey, label, errorMessages);
    checkForMinAndMaxLength(controlConfig, localData, state, dataKey, label, errorMessages);
    checkForMinAndMaxValue(controlConfig, localData, state, dataKey, label, errorMessages);
    checkForRegExPattern(controlConfig, localData, state, dataKey, label, errorMessages);
    checkCustomValidations(controlConfig, localData, row, state, dataKey, label, errorMessages);
    checkForRequiredField(controlConfig, localData, state, dataKey, label, errorMessages);
    checkForRequiredFieldExpression(controlConfig, localData, row, state, dataKey, label, errorMessages);
    return errorMessages;
};
