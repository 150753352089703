import React from 'react';

interface MetricData {
    value: number | string;
    rowUUID: string;
    metricType: string;
    metricEntity2: string;
}

interface WidgetData {
    data: {
        wgdata: any;
        wginfodata: {
            data: {
                metricData: MetricData[];
                widgetData: any;
                popupData: any;
            }
        }
    };
    internal: any;
}

interface DirectoryStatsProps {
    widgetData: WidgetData;
}

const DirectoryStats: React.FC<DirectoryStatsProps> = ({ widgetData }) => {
    let metricData = widgetData?.data?.wginfodata?.data?.metricData;
    let data = widgetData?.data?.wginfodata?.data?.widgetData;
    let metricEntityDomain = widgetData?.internal?.[widgetData?.data?.wgdata?.code];

    const METRIC_ENTITY_CLASS_MAP: any = {
        joined_alumni: 'hat',
        different_career_chosen: 'brifecase',
        companies_represented: 'building',
        countries_represented: 'global',
        alumni_at_top_colleges: 'college',
        alumni_at_top_companies: 'building',
        scholarship_received: 'scholarship'
    }
    return (
        <>
            <h4 className="font-30 font-600 text-center mb-2">
                {data?.largeHeading}
            </h4>
            <p className="text-center pb-3">
                {data?.mediumHeading}
            </p>
            <div className="alumni_network_count">
                <ul>
                    {metricData?.filter((data: any) => data?.statMetricEntity != "").map((data: any, index: any) => (
                        <li key={data?.rowUUID}>
                            <div className={`icon ${METRIC_ENTITY_CLASS_MAP[data?.statMetricEntity]}`}></div>
                            <b>{data.statMetricEntity == 'scholarship_received' && ('₹')} {data?.displayValue}</b>
                            <p className="max-170">
                                {metricEntityDomain[data.statMetricEntity]?.value}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default DirectoryStats;
