// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
}

.icon-verified:before {
    content: '\\e942';
    font-family: 'Material Icons';
}

html,
body {
    margin: 0;
}

.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-wrap {
    flex: 1 1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#loadingSpinner {
    width: 3rem;
    height: 3rem;
    animation: spin 2s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;;IAEI,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kCAAkC;AACtC","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n}\n\n.icon-verified:before {\n    content: '\\e942';\n    font-family: 'Material Icons';\n}\n\nhtml,\nbody {\n    margin: 0;\n}\n\n.page-container {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n}\n\n.content-wrap {\n    flex: 1;\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n#loadingSpinner {\n    width: 3rem;\n    height: 3rem;\n    animation: spin 2s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
