import React from 'react';
import { ApplicationContext, ControlValueChangeArgs, DispatchEvent, State } from './SmartTypes';
import { Tooltip } from 'bootstrap';
import { validateFormField } from './FormFieldValidation';

export const SmartContext = React.createContext({
    state: {
        flags: { isDataLoading: true, showFormErrors: 0 },
        modalFlags: { showEmailModal: false, showUserModal: false, showEmailPreviewModal: false, showWhatsAppPreviwModal: false,showsubjectmarksModal:false,showGuidanceVideoAddModal:false },
        modalData: { title: '', data: {}},
        formValidationErrors: {},
    },
    dispatch: (dispatchEvent: DispatchEvent) => {},
} as ApplicationContext);
 
/**
 * @func converting a normal array into associative array;
 * @dt 28th.June,2k24 @by RG;
 */
export function getAssociativeArray(data: any, idx: any) {
    return data.reduce((acc: any, item: any) => {
        acc[item[idx]] = item;
        return acc;
    }, {});
}

/**
 * @func converting number to percentage in the given array;
 * @param data
 * @returns data
 */
export const convertToPercentage = (data: any) => {
    // First, determine the total count
    const totalCount = data.reduce((sum: number, item: any) => sum + item.value, 0);

    // Map over the array and compute the percentage for each entry
    return data.map((item: any) => ({
        label: item.label,
        value: parseFloat(((item.value * 100) / totalCount).toFixed(1)), // Rounded to one decimal
        actualValue: item.value,
    }));
};

/**
 * @func checking whether the given value is empty or not;
 * @param {any} value
 * @returns {boolian} isEmptyFlag
 * @dt 22nd.July,2K24 @by RG;
 */
export const isEmpty = (value: any): any => {
    if (value === null || value === undefined) {
        return true;
    }

    if (value === '' || value === 0) return true;

    if (Array.isArray(value)) {
        if (value.length === 0) {
            return true;
        }

        // checking whether the inner elements are empty to restrict empty nested array to be resulted as not empty;
        let isEmptyFlag = true;
        for (let i = 0; i < value.length; i++) {
            isEmptyFlag = isEmpty(value[i]);
            if (!isEmptyFlag) {
                return isEmptyFlag;
            }
        }

        return isEmptyFlag;
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0 && value.constructor === Object;
    }

    if (value instanceof Map && value.size === 0) {
        return true;
    }

    return false;
};


/**
 * Initializes tooltips on elements with the 'data-bs-toggle="tooltip"' attribute.
 * The tooltips are created using Bootstrap's Tooltip class.
 *
 * @remarks
 * This function is intended to be called after the DOM has been fully loaded.
 * It waits for 100 milliseconds before initializing the tooltips to ensure that
 * all elements have been rendered.
 *
 * @returns {void}
 */
export const initTooltip = () => {
    setTimeout(() => {
        [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).map(function (tooltipEl) {
            // Create a new Tooltip instance for each selected element
            return new Tooltip(tooltipEl);
        });
    }, 100);
}


export const evaluateExpression = (expression: string, model: any, row: any = {}) => {
    if (isEmpty(model) || isEmpty(expression)) return;
    const dynamicFunc = (expression: string, model: any, row: any) => new Function('model', 'row', 'return ' + expression + ';');
    return dynamicFunc(expression, model, row)(model, row);
};

export const evaluateExpressionWithValue = (expression: string, data: any, model: any, row: any = {}) => {
    if (isEmpty(model) || isEmpty(expression)) return;
    const dynamicFunc = (expression: string) => new Function('model', 'row', 'data', 'return ' + expression + ';');
    console.log(dynamicFunc, ' dynamicFunc ');
    return dynamicFunc(expression)(model, row, data);
};


export const getParentData = (key: string, state: State, defaultReturnValue: any = ''): any[] | string | number | undefined | any => {
    if (key === 'user.schoolId') {
        return getControlValueFromState('user.schoolId', state, null);
    }
    if (key.split('.').includes('schoolId')) return getControlValueFromState('schoolId', state, null);
    return getControlValueFromState(key, state);
};


export const getControlValueFromState = (
    key: string,
    state: State,
    defaultReturnValue: any = ''
): any[] | string | number | undefined | any => {
    if (isEmpty(state.data)) return [];
    if (key === '') return state.data as State;
    try {
        return key.split('.').reduce((a, c) => a[c], state.data);
    } catch (e) {
        return defaultReturnValue;
    }
    // return undefined;
};


export const handleControlValueChange = (args: ControlValueChangeArgs) => {
    const { dataKey, parentDataKey, dispatch, state, control, value } = args;

    const row = getControlValueFromState(parentDataKey, state as State);

    // Validate the control value for the validations error
    const errorMessages = validateFormField(control, value, state, control.props.label, dataKey, row);

    // Call the associated function
    if (state.actions[control.id])
        state.actions[control.id]({ control, value, row, dataKey, parentDataKey, state, dispatch, errorMessages });

    dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey, name: control.id, value, errorMessages } });
};