export const ERROR_MESSAGES = {
    default: "Oops..! Something went wrong, Please try again in a while"
};

export const INTERACTIVE_POPUP_WIDGETS = ['enquiry_form', 'admission_referral_form'];
export const INTERACTIVE_POPUP_WIDGETS_IFRAME_HEIGHT = '465px';
export const IND_MOBILE_NUM_REGEX = /^[6-9]\d{9}$/;
export const OTHER_MOBILE_NUM_REGEX =  /^[1-9]\d{6,14}$/;

export const OPEN_BTN_CSS = `
    @import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
    * {font-family: "Jost", sans-serif;}
    .font-16 {font-size: 16px !important;}
    .font-22 {font-size: 22px !important;}
    .font-500 {font-weight: 500 !important;}
    .font-600 {font-weight: 600 !important;}
    .form-control, .form-select {height: 45px;}
    .form-captcha {width: 100%;height: 45px;border: 1px solid #D2D2D2;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: center;justify-content: center;border-radius: 4px;-webkit-border-radius: 4px;-moz-border-radius: 4px;-ms-border-radius: 4px;-o-border-radius: 4px;}
    .btn-darkblue {background: #0b3454;border-radius: 27px;height: 40px;padding: 0 25px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;justify-content: center;align-items: center;color: #fff;text-decoration: none;font-size: 16px;line-height: 16px;font-weight: 500;position: relative;overflow: hidden;border: 0;}
    .btn-darkblue span {position: relative;}
    .btn-darkblue::before {position: absolute;content: "";left: 0;top: 0;bottom: 0;width: 0;background: #08273f;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out;}
    .btn-darkblue:hover {color: #fff;}
    .btn-darkblue:hover::before {width: 100%;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out;}
    .modal-dialog .modal-content .modal-header .btn-close {background-color: none;background-image: url(https://www.univariety.xyz/prototype/school_dashboard/images/close.svg);width: 15px;padding: 0;font-size: 12px;margin-right: 10px;color: #000000;opacity: 2;}
    .modal-dialog .modal-content .modal-header .btn-close::before {display: none}
    .btn-close-small {width: 12px;height: 12px;padding: 0 !important;position: absolute;top: 25px;right: 25px;z-index: 9;}
    .btn-vertical-popup {position: fixed;z-index: 9;top: 270px;width: 40px;right: 0;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-direction: column;padding: 20px 0px;border-radius: 0 10px 10px 0;-webkit-border-radius: 0 10px 10px 0;-moz-border-radius: 0 10px 10px 0;-ms-border-radius: 0 10px 10px 0;-o-border-radius: 0 10px 10px 0;text-decoration: none;margin: 2px 0px;writing-mode: vertical-rl;text-orientation: mixed;transform: rotate(180deg);-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);white-space: nowrap;writing-mode: vertical-rl;	align-items: center;border: 0;background-color: #0B3454;color: #ffffff;line-height: 40px;font-weight: 600;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}
    .btn-vertical-popup:hover{background-color: #398227;}
    .btn-horizontal-popup { position: fixed; bottom: 0; right: 30px; padding: 8px 20px; font-weight: 600; border: 0; border-radius: 7px 7px 0 0px; background: #0B3454; color: #fff; -webkit-border-radius: 7px 7px 0 0px; -moz-border-radius: 7px 7px 0 0px; -ms-border-radius: 7px 7px 0 0px; -o-border-radius: 7px 7px 0 0px;}
    .btn-horizontal-popup:hover{background-color: #398227;}            
    .btn-vertical-bottom-left-popup{position: fixed;z-index: 9;bottom: 50px;width: 40px;left: 0;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-direction: column;padding: 20px 0px;border-radius: 0 10px 10px 0;-webkit-border-radius: 0 10px 10px 0;-moz-border-radius: 0 10px 10px 0;-ms-border-radius: 0 10px 10px 0;-o-border-radius: 0 10px 10px 0;text-decoration: none;margin: 2px 0px;writing-mode: vertical-rl;text-orientation: mixed;transform: rotate(0deg);-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);white-space: nowrap;writing-mode: vertical-rl;	align-items: center;border: 0;background-color: #0B3454;color: #ffffff;line-height: 40px;font-weight: 600;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;z-index:9}
    .btn-vertical-bottom-right-popup{position: fixed;z-index: 9;bottom: 50px;width: 40px;right: 0;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-direction: column;padding: 20px 0px;border-radius: 0 10px 10px 0;-webkit-border-radius: 0 10px 10px 0;-moz-border-radius: 0 10px 10px 0;-ms-border-radius: 0 10px 10px 0;-o-border-radius: 0 10px 10px 0;text-decoration: none;margin: 2px 0px;writing-mode: vertical-rl;text-orientation: mixed;transform: rotate(180deg);-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);white-space: nowrap;writing-mode: vertical-rl;	align-items: center;border: 0;background-color: #0B3454;color: #ffffff;line-height: 40px;font-weight: 600;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;z-index:9}
    .btn-vertical-center-left-popup{position: fixed;z-index: 9;bottom: 0;top: 0;max-height: 290px;   width: 40px;left: 0;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-direction: column;padding: 20px 0px;border-radius: 0 10px 10px 0;-webkit-border-radius: 0 10px 10px 0;-moz-border-radius: 0 10px 10px 0;-ms-border-radius: 0 10px 10px 0;-o-border-radius: 0 10px 10px 0;text-decoration: none;margin: auto;writing-mode: vertical-rl;text-orientation: mixed;transform: rotate(0deg);-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);white-space: nowrap;writing-mode: vertical-rl;	align-items: center;border: 0;background-color: #0B3454;color: #ffffff;line-height: 40px;font-weight: 600;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;z-index:9}
    .btn-vertical-center-right-popup{position: fixed;z-index: 9;bottom: 0;top: 0;max-height: 290px;   width: 40px;right: 0;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-direction: column;padding: 20px 0px;border-radius: 0 10px 10px 0;-webkit-border-radius: 0 10px 10px 0;-moz-border-radius: 0 10px 10px 0;-ms-border-radius: 0 10px 10px 0;-o-border-radius: 0 10px 10px 0;text-decoration: none;margin: auto;writing-mode: vertical-rl;text-orientation: mixed;transform: rotate(180deg);-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);white-space: nowrap;writing-mode: vertical-rl;	align-items: center;border: 0;background-color: #0B3454;color: #ffffff;line-height: 40px;font-weight: 600;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;z-index:9}
    .btn-vertical-top-left-popup{position: fixed;z-index: 9;top: 100px;width: 40px;left: 0;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-direction: column;padding: 20px 0px;border-radius: 0 10px 10px 0;-webkit-border-radius: 0 10px 10px 0;-moz-border-radius: 0 10px 10px 0;-ms-border-radius: 0 10px 10px 0;-o-border-radius: 0 10px 10px 0;text-decoration: none;margin: 2px 0px;writing-mode: vertical-rl;text-orientation: mixed;transform: rotate(0deg);-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);white-space: nowrap;writing-mode: vertical-rl;	align-items: center;border: 0;background-color: #0B3454;color: #ffffff;line-height: 40px;font-weight: 600;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;z-index:9}
    .btn-vertical-top-right-popup{position: fixed;z-index: 9;top: 100px;width: 40px;right: 0;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-direction: column;padding: 20px 0px;border-radius: 0 10px 10px 0;-webkit-border-radius: 0 10px 10px 0;-moz-border-radius: 0 10px 10px 0;-ms-border-radius: 0 10px 10px 0;-o-border-radius: 0 10px 10px 0;text-decoration: none;margin: 2px 0px;writing-mode: vertical-rl;text-orientation: mixed;transform: rotate(180deg);-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);white-space: nowrap;writing-mode: vertical-rl;	align-items: center;border: 0;background-color: #0B3454;color: #ffffff;line-height: 40px;font-weight: 600;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;z-index:9}
    .btn-horizontal-bottom-center-popup{position: fixed;bottom: 0;left: 0;right: 0;margin: auto;padding: 8px 20px;font-weight: 600;border: 0;border-radius: 7px 7px 0 0px;background: #0B3454;color: #fff;-webkit-border-radius: 7px 7px 0 0px;-moz-border-radius: 7px 7px 0 0px;-ms-border-radius: 7px 7px 0 0px;-o-border-radius: 7px 7px 0 0px;max-width: 290px;z-index:9}
    .btn-horizontal-bottom-left-popup {position: fixed;bottom: 0;left: 30px;padding: 8px 20px;font-weight: 600;border: 0;border-radius: 7px 7px 0 0px;background: #0B3454;color: #fff;-webkit-border-radius: 7px 7px 0 0px;-moz-border-radius: 7px 7px 0 0px;-ms-border-radius: 7px 7px 0 0px;-o-border-radius: 7px 7px 0 0px;z-index:9}
    .btn-horizontal-bottom-right-popup {position: fixed;bottom: 0;right: 30px;padding: 8px 20px;font-weight: 600;border: 0;border-radius: 7px 7px 0 0px;background: #0B3454;color: #fff;-webkit-border-radius: 7px 7px 0 0px;-moz-border-radius: 7px 7px 0 0px;-ms-border-radius: 7px 7px 0 0px;-o-border-radius: 7px 7px 0 0px;z-index:9}
`;