import React from 'react';
import { Highlight, themes } from 'prism-react-renderer';

const CodeBlock = ({ code }) => (
  <Highlight theme={themes.gruvboxMaterialLight} code={code} language="javascript">
    {({ style, tokens, getLineProps, getTokenProps }) => (
    //   <pre style={{ ...style, padding: '20px', overflowX: 'auto' }}>
    <pre style={{ ...style, fontFamily: '"Source Code Pro", monospace', fontSize: '16px' }}>
        {tokens.map((line, i) => (
          <div {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              <span {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </pre>
    )}
  </Highlight>
);

export default CodeBlock;