import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

interface Alumni {
    name: string;
    headline: string;
    profileImg: string;
    logoImg: string;
}

interface WidgetData {
    data: {
        instWiseListData: any;
        wgdata: any;
        wginfodata: {
            data: {
                widgetData: Alumni[];
            };
        };
    };
    internal: any;
}

interface AlumniSpotlightProps {
    widgetData: WidgetData;
}

const AlumniSpotlight: React.FC<AlumniSpotlightProps> = ({ widgetData }) => {
    console.log(widgetData, "widgetData")
    const alumniData: any = widgetData?.data?.instWiseListData;


    // Define Owl Carousel options
    const options = {
        items: 1,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true
    };

    return (
        <>
        <h4 className="font-30 font-600 text-center mb-0">{widgetData?.data?.wgdata?.large_heading}</h4>
        <div className="alumni-card-wrap pt-4">
            <OwlCarousel className="alumni-card-slider owl-carousel owl-theme owl-loaded owl-drag" {...options}>
                {alumniData.map((alumni: any, index: any) => (
                    <div key={index} className="item">
                        <div className="alumni-card">
                            <div className="alumni-detail">
                                <div className="alumni-img-wrap">
                                    <div className="alumni-img">
                                        <img
                                            src={alumni?.profile_pic}
                                            width="80"
                                            className="w-auto"
                                            alt={`Profile of ${alumni?.user_name}`}
                                        />
                                    </div>
                                </div>
                                <h3>
                                    {alumni?.user_name}
                                </h3>
                                <h4>
                                    {alumni?.headline_spotlight}
                                </h4>
                            </div>
                            <div className="alumni-logo">
                                <a href="javascript:;" className="alumni-link">
                                    <img
                                        src={alumni?.inst_profile_pic}
                                        width="90"
                                        className="img-fluid"
                                        alt="Alumni Logo"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </div>
        </>
    );
};

export default AlumniSpotlight;
