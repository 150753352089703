import React, { useEffect, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { isEmpty } from '../../library/Core/SmartContext';

interface MetricData {
    value: number | string;
    rowUUID: string;
    metricType: string;
    metricEntity2: string;
}

interface WidgetData {
    data: {
        wgdata: any;
        wginfodata: {
            data: {
                metricData: MetricData[];
                widgetData: any;
                popupData: any;
            }
        }
    };
    internal: any;
}

interface TopCompanyProps {
    widgetData: WidgetData;
}

const TopCompaniesVertical: React.FC<TopCompanyProps> = ({ widgetData }) => {
    let metricData = widgetData?.data?.wginfodata?.data?.metricData;
    let data = widgetData?.data?.wginfodata?.data?.widgetData;
    let metricEntityDomain = widgetData?.internal?.[widgetData?.data?.wgdata?.code];
    const popupData = widgetData?.data?.wginfodata?.data?.popupData;
    const [activeKey, setActiveKey] = useState("");

    const handleOnClick = (key: any) => {
        setActiveKey(key);
    }

    useEffect(() => {
        setActiveKey(metricData[0]['metricEntity2']);
    }, []);

    return (
        <>
            <h4 className="font-30 font-600 text-center mb-2">{data?.largeHeading}</h4>
            <p className="text-center pb-3">{data?.mediumHeading}</p>
            <div className="row g-2 g-md-4">
                <div className="col-md-auto">
                    <div className="leftPaneTab">
                        <div className="nav flex-row flex-md-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            {metricData?.filter((data: any) => data?.metricEntity2 > 0).map((data: any, index: any) => (
                                <button
                                    key={data?.metricEntity2}
                                    className={`nav-link ${index == 0 ? 'active' : ''}`}
                                    id={`v-pills-${data?.metricEntity2}-tab`}
                                    onClick={() => { handleOnClick(data?.metricEntity2) }}
                                    data-bs-toggle="pill"
                                    data-bs-target={`#v-pills-${data?.metricEntity2}`}
                                    type="button" role="tab" aria-controls={`v-pills-${data?.metricEntity2}`}
                                    aria-selected="false">
                                    {metricEntityDomain[data.metricEntity2]?.value} ({data?.cnt})
                                </button>
                            ))}
                            {data?.other > 0 && (
                                <button className="nav-link" id="v-pills-others-tab" data-bs-toggle="pill" data-bs-target="#v-pills-others" type="button" role="tab" aria-controls="v-pills-others" aria-selected="false">Others ({data?.other})</button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md">
                    <div className="tab-content bg-white border-radius-6 h-100 p-4" id="v-pills-tabContent">
                        {Object.keys(popupData).map((key) => (
                            <div key={`img-${key}`} className={`tab-pane ${key == activeKey ? 'active show' : 'fade'}`} id={`v-pills-${key}`} role="tabpanel" aria-labelledby={`v-pills-${key}-tab`}>
                                <div className={`row g-4 row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-${data?.gridLayout == '4_in_row' ? '4' : '5'} align-items-center`}>
                                    {popupData[key].map((listData: any) => (
                                        <div key={listData?.id} className="col">
                                            <div className="logo-listed">
                                                <img
                                                    src={!isEmpty(listData?.logo) ? listData?.logo : `${process.env.REACT_APP_IMAGE_BASEPATH}/building.svg`}
                                                    alt={listData?.name}
                                                    title={listData?.name}
                                                    className="img-fluid rounded"
                                                    data-bs-toggle="tooltip"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        {!isEmpty(data?.otherList) && (
                            <div key={`img-others`} className={`tab-pane ${'others' == activeKey ? 'active show' : 'fade'}`} id={`v-pills-others`} role="tabpanel" aria-labelledby={`v-pills-others-tab`}>
                                <div className={`row g-4 row-cols-2 row-cols-sm-3 row-cols-md-${data?.gridLayout == '4_in_row' ? '4' : '5'} align-items-center`}>
                                    {data?.otherList.map((listData: any) => (
                                        <div key={listData?.id} className="col">
                                            <div className="logo-listed">
                                                <img
                                                    src={!isEmpty(listData?.logo) ? listData?.logo : `${process.env.REACT_APP_IMAGE_BASEPATH}/building.svg`}
                                                    alt={listData?.name}
                                                    title={listData?.name}
                                                    className="img-fluid rounded"
                                                    data-bs-toggle="tooltip"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopCompaniesVertical;
