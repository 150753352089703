import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { isEmpty } from '../../library/Core/SmartContext';

interface WidgetData {
    data: {
        bannerData: {
            [key: string]: any;
        }[];
        wgdata: any;
        wginfodata: {
            data: {
                bannerData: any;
            };
        };
    };
    internal: any;
}

interface CoverBannerProps {
    widgetData: WidgetData;
}

const CoverBanner: React.FC<CoverBannerProps> = ({ widgetData }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalSrc, setModalSrc] = useState<string>('');
    
    const bannerData = widgetData?.data?.wginfodata?.data?.bannerData || [];
    
    const options = {
        items: 1,
        nav: true,
        dots: false,
        // autoplay: true,
        autoplayHoverPause: true,
        loop: false,
        margin: 10,
    };

    const handleModalOpen = (videoSrc: string) => {
        setModalSrc(videoSrc);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setModalSrc('');
    };

    const getEmbedUrl = (url: string | undefined) => {
        if (!url) return '';
        return url.includes('?') ? `${url}&autoplay=1` : `${url}?autoplay=1`;
    };

    return (
        <div className="alumni-card-slider">
            <OwlCarousel id='alumni-card-slider' className="alumni-card-slider owl-carousel owl-theme preview-slider owl-loaded owl-drag" {...options}>
                {bannerData.map((banner: any, index: number) => (
                    <div className="item" key={index}>
                        <div className="univariety_main_banner">
                            <div className="univariety_main_banner_container">
                                <picture>
                                    <source media="(max-width:767px)" srcSet={banner?.coverImageSm ?? banner?.coverImage} />
                                    <img src={banner?.coverImage} alt="Community Banner" width="100%" />
                                </picture>
                                <div className="main_banner_text">
                                    <div className="banner_title">{banner?.largeHeading}</div>
                                    <div className="banner_sub_title">{banner?.mediumHeading}</div>
                                    {!isEmpty(banner?.introLink) && (
                                        <a
                                            className="btn-watch video-btn"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleModalOpen(banner.embedUrl);
                                            }}
                                        >
                                            <i></i>
                                            Watch Intro
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>

            {isModalOpen && (
                <div className="modal fade show" id="video_pop" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0">
                                <button type="button" className="btn-close" onClick={handleModalClose} aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div className="ratio ratio-16x9">
                                    <iframe
                                        className="embed-responsive-item"
                                        src={getEmbedUrl(modalSrc)}
                                        id="video"
                                        style={{ 
                                            border: 'none',
                                            display: 'block',
                                        }}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        title="Video"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen={true}
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            )}
        </div>
    );
};

export default CoverBanner;
