import ErrorPage from '../library/SimpleControls/ErrorPage';

// Seerish  on 20-05-2024
import WidgetPreview from '../features/widgets/WidgetPreview';
import WidgetPreviewService from '../features/widgets/WidgetPreviewService';
import RenderWidget from '../features/widgets/RenderWidget';
import RenderWidgetService from '../features/widgets/RenderWidgetService';

// Seerish on 20-05-2024

const routes = [
    {
        path: '/widgetpreview/:id',
        errorElement: <ErrorPage />,
        loader: async ({ params }) => {
            return WidgetPreviewService.pageLoader({
                ...params,
                pageName: 'widget-list'
            });
        },
        element: <WidgetPreview />
    },
    {
        path: '/widget/:id',
        errorElement: <ErrorPage />,
        loader: async ({ params }) => {
            return RenderWidgetService.pageLoader({ ...params, pageName: 'render-widget' });
        },
        element: <RenderWidget />
    }
];

export default routes;