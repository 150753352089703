import axiosClient from '../../library/Service/axiosClient';

class WidgetPreviewService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};

        return Promise.all([
            this.getWedgitlist(params.id),
        ]).then((values) => {
            console.log(values[0])
            state.data = {
                wedgitData: values[0].data ?? [],
            };
            return Promise.resolve(state);
        });
    }

    static getWedgitlist(id: string) {
        return axiosClient.get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidget/${id}`);
    }
}

export default WidgetPreviewService;
