import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CustomPieChartComponent from '../../library/SimpleControls/CustomPieChartControl';

interface MetricData {
    value: number | string;
    rowUUID: string;
    metricType: string;
    metricEntity: string;
}

interface WidgetData {
    data: {
        wgdata: any;
        wginfodata: {
            data: any
        }
    };
    internal: any;
}

interface TopCareerProps {
    widgetData: WidgetData;
}

const TopCareerPieChart: React.FC<TopCareerProps> = ({ widgetData }) => {
    let metricData = widgetData?.data?.wginfodata?.data?.metricData;
    let data = widgetData?.data?.wginfodata?.data?.widgetData;
    let metricEntityDomain = widgetData?.internal?.[widgetData?.data?.wgdata?.code];
    let pieData = metricData?.filter((filterData: any) => filterData.value > 0).map((rec: any, index: any) => {
        let value = rec?.value;

        return {
            label: metricEntityDomain?.[rec?.metricEntity]?.value,
            value: value
        }
    });

    pieData.push({ label: 'Other', value: data?.other })

    return (
        <div className="Vertical-graph-block mx-auto">
            <h2>{data?.heading}</h2>
            <div className="bigHorizontalProgress">
                <CustomPieChartComponent data={pieData} />
            </div>
        </div>
    );
};

export default TopCareerPieChart;