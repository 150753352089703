const ErrorPage = () => {
    let message = 'Something went wrong!';

    const handleRedirect = () => {
        let redirectUrl = '/';
        const userTypeCode = sessionStorage.getItem('user-type-code');

        if (userTypeCode === 'ALUMNI') {
            redirectUrl = '/alumni/onboarding';
        } else if (userTypeCode === 'STUDENT') {
            redirectUrl = '/alumni/directory';
        } else if (userTypeCode === 'SCHOOL_ADMIN') {
            redirectUrl = '/management/dashboard';
        }

        // TODO: We need to figure out a way to make the navigate work here.
        window.location.href = redirectUrl;
    };

    return (
        <div style={{ padding: '50px', textAlign: 'center' }}>
            <h1>{message}</h1>
            <p>Please try again or contact our support if the problem persists.</p>
            <p>
                <a role="button" onClick={handleRedirect}>
                    Go to Home Page
                </a>
                {/* <Link to="/alumni/onboarding">Go to Home Page</Link> */}
            </p>
        </div>
    );
};

export default ErrorPage;
