import React, { useContext, useEffect, useState } from 'react';
import axiosClient from '../../library/Service/axiosClient';
import { useLoaderData, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import smartReducer from '../../library/Core/SmartReducer';
import Swal from 'sweetalert2';
import { INTERACTIVE_POPUP_WIDGETS, INTERACTIVE_POPUP_WIDGETS_IFRAME_HEIGHT, OPEN_BTN_CSS } from '../../constants/constants';
import CodeBlock from '../../library/SimpleControls/CodeBlock';
// const buttonStyles = require('../../styles/css/open-btn.css').default;
// import buttonStyles from '../../styles/css/open-btn.css';

const WidgetPreview = () => {
// console.log(buttonStyles, " buttonStyles ")
// console.log(buttonStyles.default, " buttonStyles.default ")

    const { id } = useParams<{ id: string }>(); // Get the `id` from the UR
    const loaderData = useLoaderData() as any;
    let wgdata = loaderData?.data?.wedgitData?.wgdata;
    let wginfodata = loaderData?.data?.wedgitData?.wginfodata?.data;
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
      } as any);
    
    const [isPublished, setIsPublished] = useState(wgdata?.is_published);

    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { loaderData },
        });
    }, [loaderData]);
    
    const handlePublish = async () => {
        try {
          const response = await axiosClient.get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/publishwidget/${id}`);
          console.log('Publish successful:', response.data); // Log success message or handle response data
            Swal.fire({
                title: "Done!",
                text: "Widget has been published successfully..!",
                icon: "success"
              });
          setIsPublished(1);
          // Optionally, you can show a success message or update UI state here
        } catch (error) {
          console.error('Error publishing widget:'); // Log error message
          // Handle errors gracefully, e.g., display an error message to the user
        }
      };

const getIframeCss = (widget_code: any) => {
    let css = '';
    
    switch ( widget_code ) {
        case 'enquiry_form':
        case 'admission_referral_form':
            css = `
            <style type="text/css">
                ${OPEN_BTN_CSS}
            </style>
            `;
            break;
    }

    return css;
}

const getiFrameCode = () => {
    const title = wgdata?.name;
    const width = '100%';
    let height = '600px';
    const loading = 'lazy';
    const description = wginfodata?.widgetData?.largeHeading?? wginfodata?.widgetData?.heading?? wgdata?.large_heading?? wgdata?.name;
    const iframeStyle = 'border: none; vertical-align: top';
    const iframeSrc = `${process.env.REACT_APP_APP_BASE_URL}/widget/${id}`;
    
    if ( INTERACTIVE_POPUP_WIDGETS.includes(wgdata?.code) ) {
        height = INTERACTIVE_POPUP_WIDGETS_IFRAME_HEIGHT;
    }

    let iFrameCode =  `
        <iframe 
            src="${iframeSrc}"
            title="${title}" 
            width="${width}" 
            height="${height}"
            loading="${loading}"
            description="${description}"
            style="${iframeStyle}"
        ></iframe>
    `;
    
    switch ( wgdata?.code ) {
        case 'enquiry_form':
        case 'admission_referral_form':
            iFrameCode = `
            <!-- JavaScript block to handle the message event for closing the popup/modal -->
            <script>
                // Add an event listener for 'message' events sent to the window
                window.addEventListener('message', function(event) {               

                    // Check if the message contains a type of 'CLOSE_POPUP' and the success flag is true
                    if (event.data?.type === 'CLOSE_POPUP' && event.data.success) {
                        
                        // Get the modal element using the provided 'wgdata.code' as its ID
                        const modalElement = document.getElementById('${wgdata?.code}');

                        // Retrieve the Bootstrap modal instance associated with the element
                        const modalInstance = bootstrap.Modal.getInstance(modalElement);

                        // If the modal instance already exists, hide it using Bootstrap's hide method
                        if (modalInstance) {
                            modalInstance.hide();
                        } else {
                            // If no instance exists, create a new modal instance and then hide it
                            const newModalInstance = new bootstrap.Modal(modalElement);
                            newModalInstance.hide();
                        }

                        // Find the modal-backdrop element (which is the overlay behind the modal)
                        const backdropElement = document.querySelector('.modal-backdrop');
                        
                        // If a backdrop exists, remove it from the DOM
                        if (backdropElement) {
                            backdropElement.remove();
                        }
                    }
                });
            </script>

            <!-- Button to open the modal --> 
            <button class="btn-${wginfodata?.widgetData?.openButtonLayout}-${wginfodata?.widgetData?.openButtonAlignment}-popup" data-bs-toggle="modal" data-bs-target="#${wgdata?.code}">
                ${wginfodata?.widgetData?.openBtnText} <!-- Button text from widget data -->
            </button>

            <!-- Modal HTML structure -->
            <div class="modal fade" id="${wgdata?.code}" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered max-500 mx-auto"> <!-- Center the modal and set max width to 500px -->
                    <div class="modal-content overflow-hidden"> <!-- Overflow hidden for modal content to manage iframe properly -->
                        
                        <!-- Modal header with close button -->
                        <div class="modal-header px-3 px-sm-4"> <!-- Padding adjustments for different screen sizes -->
                            <button type="button" class="btn-close btn-close-small m-0" data-bs-dismiss="modal" aria-label="Close"></button> <!-- Close button -->
                            <h5 class="modal-title w-100 font-22 font-600">${wginfodata?.formConfig?.formTitle}</h5> <!-- Modal title -->
                        </div>
                        <!-- iFrame content insertion point -->
                        ${iFrameCode}
                    </div>
                </div>
            </div>

            <!-- Link to Bootstrap CSS from CDN -->
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet">

            <!-- Link to Bootstrap JS bundle from CDN (includes Popper.js) -->
            <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>

            <!-- Insert iframe styling, custom CSS for the modal -->
            ${getIframeCss(wgdata?.code)}
            `;
            break;
    }

    return iFrameCode;
}

const iframeCode = getiFrameCode();

const copyToClipboard = () => {
    navigator.clipboard.writeText(iframeCode)
        .then(() => {
            Swal.fire({
                title: "Copied successfully!",
                text: "You can now paste this iframe code wherever you need..!",
                icon: "success"
              });
        })
        .catch((err) => {
            console.error('Failed to copy: ', err);
        });
};

const renderIframe = () => {
    let height = '600px';
    
    if ( INTERACTIVE_POPUP_WIDGETS.includes(wgdata?.code) ) {
        height = INTERACTIVE_POPUP_WIDGETS_IFRAME_HEIGHT;
    }
    
    return (
        <iframe 
            src={`${process.env.REACT_APP_APP_BASE_URL}/widget/${id}?callFrom=preview`}
            title={wgdata?.name}
            width="100%" 
            height={height} 
            loading="lazy"
            data-description={wginfodata?.widgetData?.largeHeading ?? wginfodata?.widgetData?.heading ?? wgdata?.large_heading}
            style={{ border: 'none', verticalAlign: 'top' }}
        ></iframe>
    );
}

return (
        <>
            <div className='main flex-1 bg-white'>
                <div className="max-1140 mx-auto pt-5">
                    <div className="d-flex justify-content-between align-items-center pb-3">
                        <h3 className="font-30 font-600 mb-0 block-title position-relative">
                            Manage Widgets For Alumni <i className="icon-right-arrow font-16 position-relative me-1"></i> <span>{wgdata?.name}</span>
                        </h3>
                    </div>
                </div>

                <div className="py-5 bg-yellow-FFFAE5">
                    <div className="max-1140 mx-auto">
                        <div className="row g-3">
                            <div className="col-12">
                                <div className="embed-wrap">
                                    {isPublished == 1 && (
                                        <div className="row g-3 justify-content-between align-items-center">
                                            <div className="col-12">
                                                <h3 className="mb-0 font-18">Embed this post</h3>
                                            </div>
                                            <div className="col-12">
                                                <hr className="m-0 dotted" />
                                            </div>
                                            <div className="col-auto">
                                                <div className="api-link-title">
                                                    Copy and paste embed code on your site
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="link-url">
                                                    <button onClick={copyToClipboard} className="btn btn-configure">
                                                        Copy Code
                                                    </button>
                                                    <i id='copy_icon' onClick={copyToClipboard}></i>
                                                </div>
                                            </div>
                                            <div className="col-12 ">
                                                <p className="text-dark-blue font-500">
                                                    {/* <pre>{iframeCode}</pre> */}
                                                    <CodeBlock code={iframeCode} />

                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {isPublished != 1 && (
                                        <span>The widget has not been published yet, Iframe code will be displayed once published.</span>
                                    )}
                                </div>
                            </div>                    
                        </div>
                    </div>
                </div>

                <div className="max-1140 mx-auto pt-5">
                    <div className="d-flex justify-content-center align-items-center pb-3">
                        <h3 className="font-30 font-600 mb-0 block-title position-relative d-flex align-items-center">
                            <i className="icon-eye-black me-2"></i> PREVIEW</h3>         
                    </div>
                </div>

                <div>
                    {renderIframe()}
                </div>
                
                <style dangerouslySetInnerHTML={{ __html: getIframeCss(wgdata?.code) }} />

                <div className="max-1140 py-5 overflow-hidden mx-auto">
                    <div className="row g-4">
                        <div className="col-sm-12">
                            <div className="action_btns mt-0 max-100per">
                                {isPublished != 1 && (
                                    <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5"  onClick={handlePublish}><span className="px-3">Publish</span></button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WidgetPreview;
