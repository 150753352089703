import React, { useEffect, useState } from 'react';
// import SelectControl from '../../../library/SimpleControls/SelectControl';
import axiosClient from '../../../library/Service/axiosClient';
import Swal from 'sweetalert2';
import { IND_MOBILE_NUM_REGEX, OPEN_BTN_CSS, OTHER_MOBILE_NUM_REGEX } from '../../../constants/constants';

interface WidgetData {
    data: {
        bannerData: {
            [key: string]: any;
        }[];
        wgdata: any;
        wginfodata: {
            data: {
                formConfig: any;
                widgetData: any

            };
        };
    };
    internal: any;
}

interface EnquiryFormProps {
    widgetData: WidgetData;
    params: any
}

const EnquiryForm: React.FC<EnquiryFormProps> = ({ widgetData, params }) => {
    const wgdata = widgetData?.data?.wgdata || [];    
    const wginfodata = widgetData?.data?.wginfodata?.data;
    const formConfig = wginfodata?.formConfig || [];
    const [countryDomain, setCountryDomain]: any = useState(widgetData?.internal?.country?.data || []);
    const [stateDomain, setStateDomain] = useState(widgetData?.internal?.state?.data || []);
    const [cityDomain, setCityDomain] = useState(widgetData?.internal?.city?.data || []);
    const [fieldToValidate, setFieldToValidate] = useState<{ id: string, value: any } | null>(null);

    // Define a function to get the initial form state
    const getInitialFormState = () => ({
        name: '',
        email: '',
        mobile_country_code: 91,
        mobile_number: '',
        state: '',
        city: '',
        program: '',
        consent: '',
        institute_id: wgdata?.institute_id,
        group_id: wgdata?.group_id
    });

    // Use the initial form state when setting the form state
    const [formState, setFormState]: any = useState(getInitialFormState());
    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        mobile_country_code: '',
        mobile_number: '',
        state: '',
        city: '',
        program: '',
        consent: '',
    });
    let topOption = new Set();

    useEffect(() => {
        setFormState({
            ...formState,
            mobile_country_code: 91
        });

        let stateDomainClone: any = []
        stateDomainClone = stateDomain.filter((item: any) => item.parentCode === '100');
        setStateDomain(stateDomainClone);

        let cityDomainClone: any = []
        cityDomainClone = cityDomain.filter((item: any) => item.parentCode === '100');
        setCityDomain(cityDomainClone);
    }, [])

    // const mobileCountryCodeControl = {
    //     id: 'mobile_country_code',
    //     dataId: 'mobile_country_code',
    //     dataPath: 'formConfig',
    //     fields: countryDomain.map((item: any) => ({
    //         label: item.value,
    //         value: item.code,
    //     })),
    //     props: {
    //         label: 'Country Code',
    //         domainCategoryCode: 'MOBILE_COUNTRY_CODE',
    //     },
    //     type: 'select', // The control type
    //     required: 1, // Boolean as number (1 for true, 0 for false)
    //     dataKey: 'mobile_country_code', // The key for the data
    //     width: 100, // Field width as a number (100%)
    //     controlGroup: [], // Empty control group (if not grouping controls)
    // };

    // const mobileCountryCodeControl = {
    //     id: 'mobile_country_code',
    //     dataId: 'mobile_country_code',
    //     dataPath: 'formConfig',
    //     fields: countryDomain.map((item: any) => ({
    //         label: item.value,
    //         value: item.code,
    //     })),
    //     props: {
    //         label: 'Country Code',
    //         domainCategoryCode: 'MOBILE_COUNTRY_CODE',
    //         defaultValue: '', // Default value for the control
    //         gridOptions: {
    //             columnDefs: [
    //                 {
    //                     id: 'country_code', // Unique identifier for the column
    //                     label: 'Country',   // Display label for the column
    //                     field: 'value',     // Field in your data
    //                     width: 150,         // Width of the column (optional)
    //                 },
    //             ],
    //             pageSize: 10, // Define page size if applicable
    //         },
    //         parentId: '', // Set parent ID if applicable
    //         parentCode: 12345, // **Changed to a number** (adjust to your actual number if applicable)
    //         dataType: 'string', // Type of the data (assuming it's a string)
    //         dataLength: 0, // Max length of the data
    //         numLines: 1, // Number of lines (default to 1 for a select control)
    //         topOptionsGroupLabelInSelect: '', // Optional, for grouping options in select
    //         labelCSS: '', // CSS class for styling the label (set your own class or leave it empty)
    //         customProperties: {}, // Custom properties, you can set specific key-value pairs if needed
    //     },
    //     type: 'select', // Type of the form control
    //     hideExpression: '', // Condition to hide the field
    //     readOnly: 0, // Set to 0 for editable field
    //     readOnlyExpression: '', // Condition to make the field read-only
    //     required: 1, // Set to 1 to make it required
    //     defaultValue: '', // Default value for the control
    //     dataKey: 'mobile_country_code', // The data key for the control
    //     width: 100, // Set the width (percentage or fixed number)
    //     controlGroup: [], // Empty array, as this isn't a grouped control
    // };


    const getSelectOptions = (domainData: any, id: any, topText: any) => {
        const getAllOptions = () => {
            return domainData
                .filter((domain: any) => ![...topOption].includes(domain.code))
                .map((domain: any, index: any) => (
                    <option key={`${index}.${domain.value}.${domain.parentCode}`} value={domain.code}>
                        {domain.value}
                    </option>
                ));
        };

        domainData
            .filter((item: any) => item.displayOrder > 0)
            .forEach((item: any) => {
                topOption.add(item.code);
            });

        return (
            <>
                <optgroup
                    key={`${id}-${topText}`}
                    label={topText}>
                    {domainData
                        .filter((item: any) => item.displayOrder > 0)
                        .sort((curr: any, next: any) => curr.displayOrder - next.displayOrder)
                        .map((domain: any, idx: any) => (
                            <option key={`${id}-${domain.value}-${idx}-top`} value={domain.code}>
                                {domain.value}
                            </option>
                        ))}
                </optgroup>
                <optgroup key={`${id}----------------------`} label="----------------------">
                    {getAllOptions()}
                </optgroup>
            </>
        );
    };
    
    const handleChangeEvent = (id: string, value: any, dataKey: string) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [dataKey]: value,
        }));
        
        // Set the field that needs validation
        setFieldToValidate({ id, value });
    };
    
    // useEffect to run validation whenever `formState` changes
    useEffect(() => {
        if (fieldToValidate) {
            validateField(fieldToValidate.id, fieldToValidate.value);
            // Reset the field to avoid re-validation
            setFieldToValidate(null);
        }
    }, [formState, fieldToValidate]);
    

    // Function to validate a specific field on change
    const validateField = (id: string, value: any) => {
        let error = '';
        switch (id) {
            case 'name':
                error = value.trim() ? '' : 'Name is required';
                break;
            case 'email':
                error = value.trim() ? (/\S+@\S+\.\S+/.test(value) ? '' : 'Email is invalid') : 'Email is required';
                break;
            case 'mobile_country_code':
                error = value ? '' : 'Mobile country code is required';
                if ( error.trim() == '') {
                    console.log(" EMPTY ERR");
                    let regex = IND_MOBILE_NUM_REGEX;
                    if ( value != 91) {
                        regex = OTHER_MOBILE_NUM_REGEX;
                    }
                    let validMobile = true;
                    validMobile = regex.test(formState?.mobile_number);
                    console.log(validMobile, " validMobile")
                    let mobileError = formState?.mobile_number ? (validMobile ? '' : 'Please provide a valid mobile number') : '';
                    console.log(mobileError, " mobileError ")
                    setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        mobile_number: mobileError,
                    }));
                }
                break;
            case 'mobile_number':
                let regex = IND_MOBILE_NUM_REGEX;
                if ( formState?.mobile_country_code != 91) {
                    regex = OTHER_MOBILE_NUM_REGEX;
                }
                let validMobile = true;
                validMobile = regex.test(value);
                error = value ? (validMobile ? '' : 'Please provide a valid mobile number') : 'Mobile number is required';
                // error = value ? (value.length === 10 ? '' : 'Please provide a valid mobile number') : 'Mobile number is required';
                break;
            case 'state':
                error = formConfig?.currentState && !value ? 'State is required' : '';
                break;
            case 'city':
                error = formConfig?.currentCity && !value ? 'City is required' : '';
                break;
            case 'program':
                error = formConfig?.programsInterestedIn && !value ? 'Program is required' : '';
                break;
            case 'consent':
                error = value !== true ? 'Consent is required' : '';
                if (error) setConsentErr(true);
                break;
            default:
                break;
        }

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [id]: error,
        }));
    };

    // Validate entire form before submission
    // Update the validateForm function like this:

    const validateForm = async () => {
        console.log(formState, " Validating formState")
        const errors: {
            name: string;
            email: string;
            mobile_country_code: string;
            mobile_number: string;
            state: string;
            city: string;
            program: string;
            consent: string;
        } = {
            name: '',
            email: '',
            mobile_country_code: '',
            mobile_number: '',
            state: '',
            city: '',
            program: '',
            consent: ''
        };

        // Manually validate each field and assign errors
        errors.name = formState.name.trim() ? '' : 'Name is required';
        errors.email = formState.email.trim() ? (/\S+@\S+\.\S+/.test(formState.email) ? '' : 'Email is invalid') : 'Email is required';
        errors.mobile_country_code = formState.mobile_country_code ? '' : 'Mobile country code is required';
        let regex = IND_MOBILE_NUM_REGEX;
        if ( formState?.mobile_country_code != 91) {
            regex = OTHER_MOBILE_NUM_REGEX;
        }
        let validMobile = true;
        validMobile = regex.test(formState.mobile_number);
        errors.mobile_number = formState.mobile_number ? (validMobile ? '' : 'Please provide a valid mobile number') : 'Mobile number is required';
        // errors.mobile_number = formState.mobile_number ? (formState.mobile_number.length === 10 ? '' : 'Please provide a valid mobile number') : 'Mobile number is required';
        errors.state = formConfig?.currentState && !formState.state ? 'State is required' : '';
        errors.city = formConfig?.currentCity && !formState.city ? 'City is required' : '';
        errors.program = formConfig?.programsInterestedIn && !formState.program ? 'Program is required' : '';
        errors.consent = formConfig?.showConsent == true && formState.consent != true ? 'Consent is required' : '';

        if (errors.consent) setConsentErr(true);

        // Update formErrors state at once after collecting all errors
        setFormErrors(errors);
        console.log(errors, " errors ")
        // Check if any errors exist
        const hasErrors = Object.values(errors).some((err) => err !== '');

        return !hasErrors;
    };


    // Function to reset the form state
    const resetForm = () => {
        setFormState(getInitialFormState());
        setFormErrors({
            name: '',
            email: '',
            mobile_country_code: '',
            mobile_number: '',
            state: '',
            city: '',
            program: '',
            consent: '',
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let proceedFurther = false;
        if (params?.callFrom == 'preview') {
            await Swal.fire({
                title: "Are you sure?",
                text: `Submitting this form from the preview page will save your data just like submitting the actual form. Please confirm your submission`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Proceed to submit!"
              }).then((result) => {
                if (result.isConfirmed) {
                    proceedFurther = true;
                }
              });
        } else {
            proceedFurther = true;
        }

        if ( ! proceedFurther ) {
            console.log(" Not Proceeding further");
            return false;
        }

        const isFormValid = await validateForm();
        if (!isFormValid) {
            console.log('Error FormState :', formState);
            return false;
        }

        const saveUrl = `${process.env.REACT_APP_BROADCAST_API_SERVER}/referral/add-inquiry`;

        const nameParts = formState.name.trim().split(' ');
        // Extract first name and last name
        const firstName = nameParts[0];
        const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';

        let formData = {
            first_name: firstName,
            last_name: lastName,
            email: formState.email,
            country_code: formState.mobile_country_code,
            contact_number: formState.mobile_number,
            state: formState?.state,
            city: formState?.city,
            // program: formState.program,
            program_text_ref: formState?.program,
            consent: formState?.consent,
            // source: "inquiry",
            source: "enquiry",
            institute_id: formState.institute_id,
            institute_group_id: formState.group_id
        }

        axiosClient
            .post(saveUrl, JSON.stringify(formData))
            .then((res: any) => {
                const responseUserData = res.data;
                if (responseUserData.success === 0) {
                    Swal.fire(responseUserData.message);
                } else {
                    // Swal.fire(responseUserData.message);
                    Swal.fire({
                        title: "Thank you..!",
                        // text: `${responseUserData.message}`,
                        text: `Submitted successfully..!!`,
                        icon: "success"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // Handle the OK button click here  
                            window.parent.postMessage({ type: 'CLOSE_POPUP', success: true }, '*');
                        }
                    });
                    resetForm();
                    // window.parent.postMessage({ type: 'CLOSE_POPUP', success: true }, '*');
                }
            })
            .catch((err: any) => {
                console.error(err);
                Swal.fire('Oops..! Something went wrong, Please try again in a while..!!');
            });
    };
    const [consentErr, setConsentErr] = useState(false);
    useEffect(() => {
        if (consentErr) {
            const timer = setTimeout(() => {
                setConsentErr(false);
            }, 3000);

            // Cleanup function to clear the timer when the component is unmounted
            return () => clearTimeout(timer);
        }
    }, [consentErr])

    return (
        <div className={`bg-white ${params?.callFrom == 'preview' ? 'border-radius-6 p-4 w-100 border max-470 mx-auto' : ''}`}>
            {params?.callFrom == 'preview' && (
                <>
                    <button className={`btn-${wginfodata?.widgetData?.openButtonLayout}-${wginfodata?.widgetData?.openButtonAlignment}-popup pe-none`}>
                        {wginfodata?.widgetData?.openBtnText}
                    </button>
                    <h3 className="font-30 font-600 text-dark-blue text-center mb-3">{formConfig?.formTitle || 'Admission Enquiry'}</h3>
                </>
            )}
            <form className="p-4" onSubmit={handleSubmit}>
                <div className="row g-4">
                    <div className="col-md-12">
                        <label className="form-label m-0 mb-1 font-16 font-500">Name*</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Enter Name"
                            value={formState.name}
                            onChange={(e) => handleChangeEvent('name', e.target.value, 'name')}
                        />
                        {formErrors.name && <small className="text-danger">{formErrors.name}</small>}
                    </div>

                    <div className="col-md-12">
                        <label className="form-label m-0 mb-1 font-16 font-500">Email Address*</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="xyz@gmail.com"
                            value={formState.email}
                            onChange={(e) => handleChangeEvent('email', e.target.value, 'email')}
                        />
                        {formErrors.email && <small className="text-danger">{formErrors.email}</small>}
                    </div>

                    <div className="col-md-12">
                        <label className="form-label m-0 mb-1 font-16 font-500">Mobile Number*</label>
                        <div className="input-group">
                            <select
                                className="form-select max-120"
                                id="mobile_country_code"
                                name="mobile_country_code"
                                value={formState.mobile_country_code}
                                onChange={(e) => handleChangeEvent('mobile_country_code', e.target.value, 'mobile_country_code')}
                                aria-label="Default select example">
                                <option value="" disabled={true} >Select Country Code</option>
                                {getSelectOptions(countryDomain, 'mobile_country_code', 'Top Countries')}
                            </select>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Ex. 77018 98761"
                                value={formState.mobile_number}
                                onChange={(e) => handleChangeEvent('mobile_number', e.target.value, 'mobile_number')}
                                name="mobile_number"
                            />
                        </div>
                        {formErrors.mobile_country_code && <small className="text-danger">{formErrors.mobile_country_code}</small>}
                        {!formErrors.mobile_country_code && formErrors.mobile_number && <small className="text-danger">{formErrors.mobile_number}</small>}
                    </div>

                    {formConfig?.currentState && (
                        <div className="col-sm-6">
                            <label className="form-label m-0 mb-1 font-16 font-500">State*</label>
                            <select
                                className="form-select"
                                id="state"
                                name="state"
                                value={formState.state}
                                onChange={(e) => handleChangeEvent('state', e.target.value, 'state')}
                            >
                                <option value="" disabled>
                                    Select State
                                </option>
                                {stateDomain?.map((item: any, index: number) => (
                                    <option key={index} value={item?.code}>
                                        {item?.value}
                                    </option>
                                ))}
                            </select>
                            {formErrors.state && <small className="text-danger">{formErrors.state}</small>}
                        </div>
                    )}

                    {formConfig?.currentCity && (
                        <div className="col-sm-6">
                            <label className="form-label m-0 mb-1 font-16 font-500">City*</label>
                            <select
                                className="form-select"
                                id="city"
                                name="city"
                                value={formState.city}
                                onChange={(e) => handleChangeEvent('city', e.target.value, 'city')}
                            >
                                <option value="" disabled>
                                    Select City
                                </option>
                                {getSelectOptions(cityDomain, 'city', 'Top Cities')}
                            </select>
                            {formErrors.city && <small className="text-danger">{formErrors.city}</small>}
                        </div>
                    )}

                    {formConfig?.programsInterestedIn && (
                        <div className="col-md-12">
                            <label className="form-label m-0 mb-1 font-16 font-500">Programs Interested In*</label>
                            <select
                                className="form-select"
                                id="program"
                                name="program"
                                value={formState.program}
                                onChange={(e) => handleChangeEvent('program', e.target.value, 'program')}
                            >
                                <option value="" disabled>
                                    Select Program
                                </option>
                                {formConfig?.displayPrograms.map((program: any, index: number) => (
                                    <option key={index} value={program}>
                                        {program}
                                    </option>
                                ))}
                            </select>
                            {formErrors.program && <small className="text-danger">{formErrors.program}</small>}
                        </div>
                    )}

                    {formConfig?.showConsent && (
                        <div className={`col-md-12 ${consentErr ? 'shake-err' : ''}`}>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="consent"
                                    value={formState.consent}
                                    checked={formState.consent}
                                    onChange={(e) => handleChangeEvent('consent', e.target.checked, 'consent')}
                                />
                                <label className="form-check-label" htmlFor="consent">
                                    I agree to receive information regarding my submitted enquiry.
                                </label>
                            </div>
                            {/* {formErrors.consent && <small className="text-danger">{formErrors.consent}</small>} */}
                        </div>
                    )}

                    <input
                        type="text"
                        id="institute_id"
                        name="institute_id"
                        className="hide"
                        value={wgdata?.institute_id}
                        onChange={(e) => handleChangeEvent('institute_id', e.target.value, 'institute_id')}
                    />
                    <input
                        type="text"
                        id="group_id"
                        name="group_id"
                        className="hide"
                        value={wgdata?.group_id}
                        onChange={(e) => handleChangeEvent('group_id', e.target.value, 'group_id')}
                    />

                    <div className="col-md-12 text-center">
                        <button type="submit" className="btn btn-darkblue mx-auto">
                            <span className="px-2">Submit</span>
                        </button>
                    </div>
                </div>
            </form>
            <style>
                {`
                ${OPEN_BTN_CSS}
                `}
            </style>
        </div>
    );
};

export default EnquiryForm;
